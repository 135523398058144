import React from "react";
import { Crisp } from "crisp-sdk-web";
import { useSession } from "next-auth/react";

const CrispChat = () => {
  const { data: session } = useSession();

  React.useEffect(() => {
    if (typeof window === "undefined") return;
    if (process.env.NODE_ENV === "development") return;
    if (!Crisp.isCrispInjected())
      Crisp.configure("e64b26f3-3eb2-4526-969e-9d62c03363f8");
    if (session?.user) {
      session.user.email && Crisp.user.setEmail(session.user.email);
      session.user.name && Crisp.user.setNickname(session.user.name);
      session.user.image && Crisp.user.setAvatar(session.user.image);
    }
  }, [session]);

  return null;
};

export default CrispChat;
