import NavMenu from "./nav-menu";
import Image from "next/image";
import Logo from "/public/img/logo.png";

const NavBar = () => {
  return (
    <nav className="fixed z-[45] h-16 w-screen overflow-hidden bg-white">
      <div className="container flex h-full items-center justify-between text-purple-600">
        <div className="flex items-center gap-2 overflow-hidden text-xl font-extrabold sm:text-3xl">
          <Image src={Logo} alt="" priority className="h-12 w-12" /> TweetWizard
        </div>
        <div className="flex items-center gap-4">
          <NavMenu />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
