import navLinks from "./nav-links";
import Link from "next/link";
// import { useSession, signIn } from "next-auth/react";
// import { Button } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  // DropdownMenuLabel,
  // DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";

type NavMenuProps = {
  white?: boolean;
};

const NavMenu = ({ white = false }: NavMenuProps) => {
  // const { data: session } = useSession();

  return (
    <>
      <div className="hidden items-center md:flex md:gap-8 lg:gap-16">
        {navLinks.map((link) => (
          <Link
            key={link.path}
            href={link.path}
            className={`${white ? "text-white underline-offset-2 hover:underline" : "text-indigo-500 hover:text-indigo-900"}`}
          >
            {link.name}
          </Link>
        ))}
        {/* {session ? (
          <>
            <Button asChild>
              <Link
                href="/app"
                // className="flex h-10 items-center rounded-md bg-indigo-800 px-4 py-2 text-sm font-medium text-indigo-50 hover:bg-indigo-700"
              >
                Proceed to App
              </Link>
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => signIn(undefined, { callbackUrl: "/app" })}>
              Sign In
            </Button>
          </>
        )} */}
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger className="md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {navLinks.map((link) => (
            <DropdownMenuItem key={link.path} asChild>
              <Link href={link.path}>{link.name}</Link>
            </DropdownMenuItem>
          ))}
          {/* <DropdownMenuSeparator /> */}
          {/* {session ? (
            <>
              <DropdownMenuItem>
                <Link href="/app">Proceed to App</Link>
              </DropdownMenuItem>
            </>
          ) : (
            <DropdownMenuItem
              onClick={() => signIn(undefined, { callbackUrl: "/app" })}
            >
              Sign In
            </DropdownMenuItem>
          )} */}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default NavMenu;
