import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { type AppType } from "next/app";
import { Inter } from "next/font/google";
import Layout from "~/components/layout";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { env } from "~/env";

import { api } from "~/utils/api";

import "~/styles/globals.css";
import { Toaster } from "~/components/ui/toaster";
import { TooltipProvider } from "~/components/ui/tooltip";
import CrispChat from "~/components/crisp";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import NextSeoWrapper from "~/utils/next-seo-wrapper";

if (typeof window !== "undefined") {
  // checks that we are client-side
  if (process.env.NODE_ENV !== "development") {
    // only run this in production -- comment to run in development
    posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
      },
    });
  }
}

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps: { session: Session | null };
};

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-sans",
});

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) => {
  if (typeof document !== "undefined") {
    document.body.classList.add(inter.variable);
  }

  const getLayout =
    Component.getLayout ??
    function (page) {
      return <Layout>{page}</Layout>;
    };

  return (
    <SessionProvider session={session as Session | null}>
      <PostHogProvider client={posthog}>
        <NextSeoWrapper />
        <Toaster />
        <CrispChat />
        <TooltipProvider>
          <div className={`font-sans ${inter.variable} bg-indigo-50`}>
            {getLayout(<Component {...pageProps} />)}
          </div>
        </TooltipProvider>
      </PostHogProvider>
    </SessionProvider>
  );
};

export default api.withTRPC(MyApp);
