import { DefaultSeo, SocialProfileJsonLd } from "next-seo";
import { config, socialProfile } from "./config";

const NextSeoWrapper = () => {
  return (
    <>
      <DefaultSeo {...config} />
      <SocialProfileJsonLd {...socialProfile} />
    </>
  );
};

export default NextSeoWrapper;
