// Next-SEO Configuration. Used by next-seo package.

import type { DefaultSeoProps, SocialProfileJsonLdProps } from "next-seo";

const config: DefaultSeoProps = {
  titleTemplate: "%s | Tweet Wizard",
  // facebook: { appId: "alhayatfoodstuff" },
  openGraph: {
    type: "website",
    locale: "en_US",
    url: "https://tweetwizard.ai",
    siteName: "Tweet Wizard",

    // urlTemplate: "https://tweetwizard.ai/%s",
  },
  twitter: {
    handle: "@TweetWizard_AI",
    site: "@TweetWizard_AI",
    cardType: "summary_large_image",
  },
};

const socialProfile: SocialProfileJsonLdProps = {
  type: "Organization",
  name: "Tweet Wizard",
  url: "https://tweetwizard.ai",
  sameAs: [
    "https://twitter.com/TweetWizard_AI",
    // "https://www.facebook.com/alhayatfoodstuff",
    // "https://www.instagram.com/eloquence.languagelearning/",
    // "https://www.tiktok.com/@eloquence.lang.learning?_t=8eSZnzpTRPW&_r=1",
    // "https://www.linkedin.com/company/alhayatfoodstuff/",
  ],
};

export { config, socialProfile };
