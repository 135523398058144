const navLinks = [
  {
    name: "Home",
    path: "/",
  },
  // {
  //   name: "Features",
  //   path: "/features",
  // },
  {
    name: "Pricing",
    path: "/pricing",
  },
  // {
  //   name: "Blog",
  //   path: "/blog",
  // },
];

export default navLinks;
